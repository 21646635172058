import React from 'react';
import CtaBlock from '../../components/components-group-one/CtaBlock';
import Footer from '../../components/footer';
import HtmlHead from '../../components/html-head';
import Navbar from '../../components/navbar';
import { PageData } from '../../types';
import ResourceHero from '../../components/resources-components/resource-hero';
import CaseStudy from '../../components/resources-components/case-study';
import miller from '../../assets/resources/markmiller.png';
import venafi from '../../assets/resources/Venafi_logo.png';
import venafipdf from '../../assets/resources/Venafi.pdf';
import { graphql } from 'gatsby';

const Venafi: React.FC<PageData> = resp => {
    const seoData = resp?.data?.allContentfulPageSeoData?.edges[0]?.node;

    const resourceData = {
        companyLogo: venafi,
        title: 'Solving for <br />Information Sprawl',
        author: {
            image: miller,
            name: '<span>Mark</span> Miller',
            title: 'Sr. Director, Enterprise Security Support',
            quote: `How am I going to find this answer? Am I going to
            pull up these eight different resources, pull up eight
            windows and go search the same terms in each
            area? 
            I have AptEdge, which is one. It was an eight fold
            gain of time.`
        },
        stats: [
            {
                percentage: '8x',
                title: 'Seach Efficiency'
            }
        ],
        pdf: venafipdf,
        ctaTitle: 'Download PDF',
        count: [],
        countColor: '#E75500',
        isdownload: true
    };

    const caseStudy = [
        {
            title: 'About',
            desc: `Venafi is a leader in machine identity management,
            safeguarding machine-to-machine connections and
            communications. Venafi secures and protects machine
            identities by using certificates and keys, offering critical
            security measures for IT infrastructures. Their clients
            include the world's largest and most security-conscious
            organizations, such as major banks, manufacturers, and
            financial institutions.`
        },
        {
            title: 'Solution',
            desc: `<span>Information Sprawl:</span> <br />
            Venafi’s decision to implement
            AptEdge was driven by its robust ability to address and
            simplify the complexities associated with information
            sprawl. Particularly its ability to aggregate scattered data
            from various platforms into a unified agent interface,
            enhancing accessibility and efficiency.
            <br /><br />
                    <span>Integration:</span> AptEdge provides a centralized search
                    feature within Venafi's customer relationship
                    management (CRM) system, enabling support agents to
                    quickly locate relevant data across various platforms,
                    including internal documents and discussions in tools like
                    Slack.`
        },
        {
            title: 'Challenge',
            desc: `<span>Information Sprawl:</span> <br />
            The dispersion of information across multiple systems
            and platforms made it difficult for support agents to
            quickly locate necessary information to address customer
            issues effectively. This challenge is accentuated by the
            growth and expansion of the organization, which leads to
            more team members, more locations, and more solutions,
            thus further compounding the issue of scattered
            information.
                    <br /><br />
            <span>Lack of Integration:</span> <br />
            Their previous solution did not integrate well into the
            user interface, nor did it adequately search across all
            locations. This lack of integration and functionality
            hindered the support team's ability to access vital
            information quickly and efficiently.`
        },
        {
            title: 'Results',
            desc: `<span>Search Efficiency: </span>The primary result from implementing
            AptEdge was found in a significant reduction in time
            spent searching. With so many knowledge sources,
            Agents previously would have to search up to 8 different
            tools with the same search terms. Now, it is just 1 search.
                    <br /><br />
                    <span>Onboarding: </span>New Agents now hit the ground running
                    sooner, resolving tougher cases with less onboarding
                    time.`
        }
    ];

    return (
        <>
            <HtmlHead
                title={seoData?.title}
                metaDescription={seoData?.metaDescription?.metaDescription}
                canonicalLink={seoData?.canonicalLink}
                openGraphImage={seoData?.openGraphImage?.file?.url}
            />
            <Navbar />
            <ResourceHero data={resourceData} />
            <CaseStudy data={caseStudy} />
            <CtaBlock ctaTitle="Start Today!" />
            <Footer />
        </>
    );
};

export default Venafi;

export const query = graphql`
    query VenafiQuery {
        allContentfulPageSeoData(filter: { pagePath: { eq: "/case-study/venafi" } }) {
            edges {
                node {
                    title
                    canonicalLink
                    metaDescription {
                        metaDescription
                    }
                    openGraphImage {
                        file {
                            url
                        }
                    }
                }
            }
        }
    }
`;